<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div class="flex-content">
        <img
          id="photo-fullscreen"
          :src="
            whappyClientAsset + 'img/logo-transparent.png' || 'img/logo.png'
          "
          @error="handleImgError"
        />
      </div>
      <ion-card>
        <ion-card-content class="position-relative">
          <ion-item>
            <ion-label position="stacked"
              >Inserisci il codice ricevuto per mail</ion-label
            >

            <ion-input
              @ionFocus="setFocusTrue"
              @ionBlur="setFocusFalse"
              v-model="userInfo.tfCode"
            ></ion-input>
          </ion-item>

          <ion-button expand="full" @click="login()">Login</ion-button>
        </ion-card-content>
      </ion-card>
      <div id="bottom-component" class="bottom-component">
        <div v-if="tastieraFocus"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  // IonCardHeader,
  // IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonPage,
  toastController,
  loadingController,
  IonLoading,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import { eye, eyeOff } from "ionicons/icons";

import UserService from "../common/mixins/User";

import VueCookies from "vue-cookies";

export default {
  components: {
    IonCard,
    // IonCardHeader,
    // IonCardTitle,
    IonCardContent,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    toastController,
  },
  mixins: [UserService],
  props: {
    username: String,
    password: String,
  },
  created() {
    this.removeDebugCookie();
  },
  data() {
    return {
      tastieraFocus: false,
      eye,
      eyeOff,
      passwordVisible: false,
      userInfo: {
        username: this.$route.params.username,
        password: this.$route.params.password,
        tfCode: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser",
    }),
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async showError() {
      const toast = await toastController.create({
        message: this.$t("login.error"),
        duration: 2000,
        color: "danger",
      });
      await toast.present();
    },
    async showOk() {
      const toast = await toastController.create({
        message: this.$t("login.success"),
        duration: 2000,
        color: "success",
      });
      await toast.present();
    },
    async login() {
      // this.presentLoading();
      if (
        this.userInfo.username &&
        this.userInfo.password &&
        this.userInfo.tfCode
      ) {
        // var md5 = require("md5");
        // let newPass = md5(this.userInfo.password);

        let newUserInfo = {
          username: this.userInfo.username,
          password: this.userInfo.password,
          tfCode: this.userInfo.tfCode,
        };

        try {
          console.log("trying login user");
          await this.loginUser(newUserInfo);
          this.dismissLoading();
          this.showOk();
        } catch (e) {
          this.dismissLoading();
          this.showError();
        }
        if (this.loginStatus === "success") {
          this.getCurrentUser({
            forceRefresh: true,
          }).then((res) => {
            if (res.NewPasswordRequired) {
              this.dismissLoading();
              this.$router.push("/changepassword");
            } else {
              this.dismissLoading();
              this.$router.push("/welcome");
            }
          });
        } else {
          this.dismissLoading();
          this.showError();
        }
      }
    },
    async presentLoading() {
      console.log("presentLoading login");

      window.loadingCPresenting = true;
      if (!window.loadingC) {
        window.loadingC = await loadingController.create({
          spinner: null,
          cssClass: "login-custom-loading",
          message: "Stiamo caricando le tue campagne...",

          // duration: 60000,
        });
      }
      if (!window.loadingCPresented) {
        await window.loadingC.present();
        window.loadingCPresented = true;
      }
    },
    async dismissLoading() {
      console.log("dismissLoading login");
      if (window.loadingCPresented) {
        await window.loadingC.dismiss();
        window.loadingCPresenting = false;
        window.loadingCPresented = false;
        // // Sic
        // const elements = document.getElementsByClassName('my-custom-loading');
        // while(elements.length > 0){
        //   elements[0].parentNode.removeChild(elements[0]);
        // }
      } else if (window.loadingCPresenting) {
        setTimeout(() => {
          this.dismissLoading();
        }, 100);
      }
    },
    removeDebugCookie() {
      console.log("debug mode:", VueCookies.get("debug"));
      VueCookies.remove("debug");
      console.log("debug mode:", VueCookies.get("debug"));
    },
    setFocusTrue() {
      this.tastieraFocus = true;
      this.scrollDown();
    },
    setFocusFalse() {
      this.tastieraFocus = false;
    },
    scrollDown() {
      document
        .querySelector("#bottom-component")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
  watch: {
    isLoading: function (e) {
      console.log("checkLoading login", e);
      if (e) {
        this.presentLoading();
      } else {
        this.dismissLoading();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.flex-content {
  margin: 0px auto;
  width: 100%;
  height: calc(100% - 300px);
  display: flex; /* Magic begins */
  flex-direction: column;
  justify-content: center; /* Center in main axis */
  align-items: center; /* Center in cross axis */
}

ion-button::part(native) {
  margin-top: 4rem;
  background-color: #fff;
  background-color: var(--ion-color-secondary);
  color: var(--client-color-text-button);
  font-size: 2rem !important;
  text-transform: none;
  border-radius: 0px;
  padding: 0.6rem 2rem;
  font-weight: bolder;
}

#photo-fullscreen {
  max-width: 100%;
  max-height: calc(100% - 100px);
}

.position-relative {
  position: relative;
}

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}
.eye-icon {
  position: absolute;
  bottom: 120px;
  right: 30px;
  color: rgb(255, 255, 255);

  z-index: 2;
}
ion-icon {
  font-size: 2rem;
  color: rgb(255, 255, 255);
}

ion-item::part(native) {
  background-color: rgba(255, 255, 255, 0);
  color: var(--ion-color-secondary);
  border-radius: 5px;
}

ion-input {
  width: 97%;
  border-bottom: 1px solid var(--text-color-light);
}

ion-content {
  --background: url("https://axerta.whappy.it/img/axerta_bg.jpg") no-repeat
    center center / cover;
}

ion-card {
  box-shadow: none;
}

.label-stacked {
  font-size: 1rem;
  color: var(--client-color-text-button) !important;
  margin-bottom: 1rem;
}
</style>
